var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"occupancy"},[(_vm.$slots.title || _vm.max_rooms > 1)?_c('div',{staticClass:"occupancy--header"},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"occupancy--title"},[_vm._t("title")],2),(_vm.internal_occupancy.length < _vm.max_rooms && _vm.max_rooms > 1)?_c('div',{staticClass:"occupancy--add-room",on:{"click":_vm.add_room}},[_c('u',[_vm._v("aggiungi camera")]),_c('vb-icon',{attrs:{"name":'plus-v2',"size":16,"color":'006ce4'}})],1):_vm._e()])],1)],1):_vm._e(),_vm._l((_vm.internal_occupancy),function(occupancy_item,room){return _c('div',{key:room,staticClass:"occupancy--room-wrapper",class:{ singleroom: _vm.max_rooms == 1 }},[_c('div',{staticClass:"occupancy--room"},[_c('b-row',[_c('b-col',{staticClass:"occupancy--adults",attrs:{"xs":"4"}},[_c('div',[_vm._v("Adulti")]),_c('div',{staticClass:"occupancy--content"},[_c('vb-icon',{attrs:{"cy":"occupancy--icon-minus","name":'minus-circle',"size":20,"color":occupancy_item.adults > 1 ? '003b95' : 'a3a3a3'},on:{"click":function($event){$event.stopPropagation();return _vm.remove_adult(room)}}}),_c('div',{staticClass:"occupancy--qty"},[_vm._v(_vm._s(occupancy_item.adults))]),_c('vb-icon',{attrs:{"cy":"occupancy--icon-plus","name":'plus-circle',"size":20,"color":occupancy_item.adults < _vm.max_adults_per_room ? '003b95' : 'a3a3a3'},on:{"click":function($event){$event.stopPropagation();return _vm.add_adult(room)}}})],1),_c('div',{staticClass:"occupancy--content mobile"},[_c('vb-select',{attrs:{"vb_options":{
                options: _vm.adults_select,
                value: occupancy_item.adults,
                size: 'sm',
              }},on:{"change":value => { _vm.set_adult(room, value) }}})],1)]),_c('b-col',{staticClass:"occupancy--children",attrs:{"xs":"4"}},[_c('div',[_vm._v("Bambini")]),_c('div',{staticClass:"occupancy--content"},[_c('vb-icon',{attrs:{"cy":"occupancy--icon-minus","name":'minus-circle',"size":20,"color":occupancy_item.children.length > 0 ? '003b95' : 'a3a3a3'},on:{"click":function($event){$event.stopPropagation();return _vm.remove_child(room)}}}),_c('div',{staticClass:"occupancy--qty"},[_vm._v(_vm._s(occupancy_item.children.length))]),_c('vb-icon',{attrs:{"cy":"occupancy--icon-plus","name":'plus-circle',"size":20,"color":'003b95'},on:{"click":function($event){$event.stopPropagation();return _vm.add_child(room)}}})],1),_c('div',{staticClass:"occupancy--content mobile"},[_c('vb-select',{attrs:{"vb_options":{
                options: _vm.children_select,
                value: occupancy_item.children.length,
                size: 'sm',
              }},on:{"change":value => { _vm.set_children(room, value) }}})],1)]),_c('b-col',{staticClass:"occupancy--remove-room",attrs:{"xs":"4"}},[_c('div',[(_vm.max_rooms > 1)?_c('div',{staticClass:"occupancy--remove-room--index"},[_c('b-badge',{attrs:{"variant":"light"}},[_vm._v("Camera "+_vm._s(room + 1))])],1):_vm._e(),(_vm.internal_occupancy.length > 1)?_c('div',{staticClass:"occupancy--remove-room--icon",on:{"click":function($event){$event.stopPropagation();return _vm.remove_room(room)}}},[_vm._v(" ✕ ")]):_vm._e()])])],1),(occupancy_item.children.length)?_c('b-row',[_c('b-col',{staticClass:"occupancy--children-disclaimer",attrs:{"cols":"12"}},[_vm._t("disclaimer")],2),_vm._l((occupancy_item.children),function(m,index){return _c('b-col',{key:_vm._uid+index,attrs:{"cols":"3","md":"6","xl":"4"}},[_c('vb-select',{staticClass:"occupancy--age",attrs:{"vb_options":{
              options: _vm.options,
              value: m,
              //size: 'sm',
            }},on:{"change":value => {_vm.set_child_age(value,index,room)}}}),_c('vb-select',{staticClass:"occupancy--age mobile",attrs:{"vb_options":{
              options: _vm.options.map(o => { return { value: o.value, text: o.value } }),
              value: m,
              size: 'sm',
            }},on:{"change":value => {_vm.set_child_age(value,index,room)}}})],1)})],2):_vm._e()],1),(_vm.pax_total == _vm.max_pax)?_c('b-row',[_c('b-col',[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Non sono disponibili pacchetti per un numero superiore di viaggiatori. Serviranno eventualmente due prenotazioni diverse. Contatta l’assistenza per maggiori informazioni.'),expression:"'Non sono disponibili pacchetti per un numero superiore di viaggiatori. Serviranno eventualmente due prenotazioni diverse. Contatta l’assistenza per maggiori informazioni.'",modifiers:{"hover":true,"top":true}}],staticClass:"occupancy--limit-reached"},[_c('vb-icon',{staticClass:"mr-1",attrs:{"name":'info',"color":'f8af3c',"size":14}}),_c('span',[_vm._v("Non puoi aggiungere altri viaggiatori")])],1)])],1):_vm._e()],1)}),(_vm.max_rooms > 1 && _vm.internal_occupancy.length == _vm.max_rooms)?_c('div',{staticClass:"text--sm"},[_vm._v(" Hai raggiunto il numero massimo di camere prenotabili, se ti occorrono più camere contatta l’assistenza. ")]):_vm._e(),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }