<template>
  <div class="vb-sidebar__block--external-message">
    <vb-icon :name="'exclamation'" :color="sass('danger')" :size="24" />
    <div class="external-message--text mt-2" v-html="external_condition.sidebar_message" />
  </div>
</template>

<script>
export default {
    name: 'external-message',
    components: {
    },
    props: {
      external_condition: {
        type: Object,
      },
    },
    computed: {
    },
    created() {
    },
    mounted() {
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style lang="scss">
.vb-sidebar__block--external-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $danger;
}
</style>