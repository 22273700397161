// vuex state structure
export const vuex_config_object = {
  global_state_key: 1,    // test purpose only
  session_state_key: 1,   // test purpose only
  booking_data: {},       // TODO: valutare se rendere site-specific
  loading: true,
  searching: false,
  token: '',
  ext_token: '',
  availability: '',       // mantengo il formato (stringa) che arriva dal config, è il front che decide se convertirlo in base all'esigenza
  discount_codes: [],
  occupancy: [
    {
      adults: 2,
      children: [],
    }
  ],
}

export const serialized_vuex_config_object = JSON.stringify(vuex_config_object)

// LE CHIAVI CONTENUTE IN shared_store_keys SARANNO CONDIVISE ED ACCESSIBILI (oltre che da tutte le sessioni) DA TUTTI I SUBMODULES:
//  - liveat
//  - livebk
//  - ...
// LEGACY:
// chiavi dello state globale che verranno scritte in localstorage
// tutte le chiavi di state che non si trovano in shared_store_keys resteranno "volatili" nello state della sessione attuale)
// global => chiavi da salvare in localstorage_store_key (il locastorage condiviso tra tutte le sessioni)
// session => chiavi da salvare in localstorage_sessions_key (nell'oggetto relativo alla sessione attuale)
export const shared_store_keys = {
  global: [
    'global_state_key',   // test purpose only
    'token',
    'ext_token',
    'discount_codes',
  ],
  session: [
    'session_state_key',  // test purpose only
    'occupancy',
  ]
}