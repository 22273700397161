<template>
  <div class="resume">
    <!--<div class="d-flex align-items-center mb-2">
      <div class="payments--icon" v-html="svg_payments"></div>
      <strong>Riepilogo pagamenti</strong>
    </div>-->
    <h5 class="vb-heading">Il tuo conto</h5>
      <div class="resume--item">
        <div class="resume--item--name">
          Pacchetti viaggio
        </div>
        <div class="resume--item--value">
          <strong>{{live_price}} €</strong>
        </div>
      </div>
      <div class="resume--item">
        <div class="resume--item--name">
          Totale
        </div>
        <div class="resume--item--value">
          <strong>{{live_price}} €</strong>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'resume',
    components: {
    },
    props: {
      live_price: String,
    },
    computed: {
    },
    created() {
    },
    mounted() {
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style lang="scss">
.resume {
  &--item {
    display:flex;
    align-items:center;
    line-height: 1;
    margin-bottom:$grid-gutter-width/4;
    &--name {
      flex:1;
      position:relative;
      display:flex;
      &:after {
        content:"";
        flex:1;
        height: inherit;
        border-bottom:1px solid $gray-300;
        margin:0 6px 2px 6px;
      }
    }
  }
}
</style>