import { vuex_subscribe_module, vuex_store_config } from '@/vuex'
import { load_state_from_local_storage } from '@/resources'

const vuex_config_object = {
  location: {
    label: '',
    path: '',
    keyd: -1,
  },
  keydsmap: [],
  products: [],
  statics: {},
  keyds: {},
  keyd: '',
  nights: '',
  departure_date: '',
  flex_dates: false,
  departure_airports: [],
  last_search: '',
  current_search: '',
  availabilities: {},
  pid: 0,
  days: 1,
  selected_room: {},
  passengers: {},
  quote: {},
  filter_keyd: [],
  filter_accommodation: [],
  filter_rating: [],
  filter_airport: [],
  filter_brand: [],
  filter_availability: false,
  sort: '',
  trigger_search: false,
  offset_scroll_search: 0,
}

export const serialized_vuex_config_object = JSON.stringify(vuex_config_object)

const state_config = vuex_store_config(vuex_config_object)

let name = 'liveat',
  state = state_config.state,
  module = {
    namespaced: true,
    state: load_state_from_local_storage(name, state_config.state),
    mutations: state_config.mutations,
    getters: state_config.getters,
    actions: state_config.actions,
    get_local_storage_submodule() {
      // dichiaro le chiavi che verranno scritte nel local storage
      return {
        location: state_config.state.location,
        keydsmap: state_config.state.keydsmap,
        products: state_config.state.products,
        statics: state_config.state.statics,
        keyds: state_config.state.keyds,
        keyd: state_config.state.keyd,
        nights: state_config.state.nights,
        departure_date: state_config.state.departure_date,
        flex_dates: state_config.state.flex_dates,
        departure_airports: state_config.state.departure_airports,
        last_search: state_config.state.last_search,
        current_search: state_config.state.current_search,
        availabilities: state_config.state.availabilities,
        pid: state_config.state.pid,
        days: state_config.state.days,
        selected_room: state_config.state.selected_room,
        passengers: state_config.state.passengers,
      }
    },
  }

export function register_state_module(prototype) {
  if (prototype.$submodules == undefined) prototype.$submodules = {}
  prototype.$submodules[name] = {
    get_local_storage_submodule: module.get_local_storage_submodule()
  }
  vuex_subscribe_module(name, state, module)
}