<template>
    <div>
      <h5 class="vb-heading">Dati di fatturazione</h5>
      <validation-observer
        v-slot="{ invalid }"
        ref="observer">
        <validation-provider
          v-for="(field,key) in guest"
          v-bind:key="'input_'+key"
          :rules="field.rules"
          :name="key"
          :vid="key"
          v-slot="{ errors, validate, failedRules }">

          <div v-if="field.type == 'input'">
            <label class="vb-label" v-html="field.name" />
            <vb-input
              @input="validate_fields($event,validate,key)"
              v-model="guest[key].value"
              :vb_options="{
                name: key,
                placeholder: field.name,
              }"
            />
            <!--<span class="vee--errors">{{ errors[0] }}</span>-->
            <div v-for="(rule,index) in failedRules" v-bind:key="_uid+index">
              <span v-if="index!=='required'" class="vee--errors">{{ rule.replace(key, field.name) }}</span>
            </div>
          </div>

          <div v-else-if="field.type == 'checkbox'">
            <vb-checkbox
              v-model="guest[key].value"
              @change="validate_fields($event,validate,key)"
              :vb_options="{
                name: key,
                text: '<p>'+field.text+'</p>',
              }"
            />
            <!--<span class="vee--errors">{{ errors[0] }}</span>-->
          </div>

        </validation-provider>
      </validation-observer>
    </div>
</template>

<script>
import * as Validation from '@resources/validation'
export default {
    name: 'checkoutBillToPax',
    components: {
    },
    props: {
      validate_on_mount: {
        type: Boolean,
        default: false,
      }      
    },
    mounted() {
      if (this.validate_on_mount) {
        let timer = setInterval(() => {
          if (this.$refs.observer) {
            clearInterval(timer)
            this.$refs.observer.validate().then(res => {
              let payload = res ? this.get_payload() : false
              this.emit_results(payload)
            })
          }
        }, 500)
      }
    },
    data() {
        return {
          guest: {
            guestFiscalCode: Validation.bill_to_pax_fiscal_code,
            guestAddress: Validation.address,
            guestCity: Validation.city,
            guestPostalCode: Validation.postal_code,
            guestProvince: Validation.province,
          }
        }
    },
    computed: {
    },
    methods: {
      get_payload() {
        let fields = this.$refs.observer.fields,
          payload = {}
        for (let index = 0; index < Object.keys(fields).length; index++) {
          const key = Object.keys(fields)[index]
          payload = {
            ...payload,
            [key]: this.guest[key].value
          }
        }
        if(this.allowCustomBeneficiary == false) payload.beneficiaryId = this.beneficiaryId

        return payload
      },

      async validate_fields(value, validate, name) {
        await validate(value).then(res => {
          if (res.valid == false) {
            this.emit_results(false)
            return
          }

          let fields = this.$refs.observer.fields

          for (let index = 0; index < Object.keys(fields).length; index++) {
            const key = Object.keys(fields)[index]
            if (key == name) continue
            if (fields[key].valid == false) {
              this.emit_results(false)
              return
            }
          }
          this.emit_results(this.get_payload())
        })
      },
      
      emit_results(payload) {
        this.$emit('change', {name: 'bill-to-pax-update', payload: payload})
      },
    }
}
</script>

<style lang="scss">
  .vb-label {
    margin-top: 0.835rem;
  }
</style>